import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
})
export class FirmwareComponent implements OnInit {
  constructor() {}

  public onFirmwarePorCliente() {
    const d1 = document.getElementById('firmware');
    const d2 = document.getElementById('firmware-por-cliente');
    const b = document.getElementById('firmware-boton');
    const b2 = document.getElementById('firmware-por-cliente-boton');
    if (d1) {
      d1.style.display = 'none';
      if (b) b.style.backgroundColor = '#404040';
      if (d2) {
        d2.style.display = 'block';
        if (b2) b2.style.backgroundColor = '#808080';
      }
    }
  }
  public onFirmware() {
    const d1 = document.getElementById('firmware');
    const d2 = document.getElementById('firmware-por-cliente');
    const b = document.getElementById('firmware-boton');
    const b2 = document.getElementById('firmware-por-cliente-boton');
    if (d2) {
      d2.style.display = 'none';
      if (b2) b2.style.backgroundColor = '#404040';
      if (d1) {
        d1.style.display = 'block';
        if (b) b.style.backgroundColor = '#808080';
      }
    }
  }
  ngOnInit(): void {}
}
