<!-- <mat-progress-bar
  mode="indeterminate"
  color="warn"
  [style.display]="loading ? 'block' : 'none'"
></mat-progress-bar> -->

<div>
  <button id="firmware-boton" style="width: 50%; height: 50px; background-color:#404040; color:white" (click)="onFirmware()">Firmware </button>
  <button id="firmware-por-cliente-boton" style="width: 50%; height: 50px; background-color:#404040; color:white" (click)="onFirmwarePorCliente()">Firmware por Cliente</button>
  <div id="firmware">
    <app-listado-firmware></app-listado-firmware>
  </div>
  <div id="firmware-por-cliente" style="display: none;">
    <app-listado-firmware-por-cliente></app-listado-firmware-por-cliente>
  </div>
</div>

