import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  ICreateFirmwarePorCliente,
  IFilter,
  IFirmware,
  IFirmwarePorCliente,
  IListado,
  IQueryParam,
  IUpdateFirmwarePorCliente,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { Subscription } from 'rxjs';

import { FirmwarePorClienteService } from '../firmware-por-cliente.service';

@Component({
  selector: 'app-crear-editar-firmware-por-cliente',
  templateUrl: './crear-editar-firmware-por-cliente.component.html',
  styleUrls: ['./crear-editar-firmware-por-cliente.component.scss'],
})
export class CrearEditarFirmwarePorClienteComponent implements OnInit {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public firmwares?: IFirmware[] = [];
  private firmwares$?: Subscription;

  public clientes?: ICliente[] = [];
  private clientes$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFirmwarePorCliente,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CrearEditarFirmwarePorClienteComponent>,
    private helper: HelperService,
    private service: FirmwarePorClienteService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Firmware por Cliente'
      : 'Crear Firmware por Cliente';
    this.form = this.fb.group({
      idCliente: [this.data?.idCliente, Validators.required],
      idFirmware: [this.data?.idFirmware, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  private async listarFirmwares(): Promise<void> {
    const filter: IFilter<IFirmware> = {};
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
      sort: '-fechaCreacion',
    };
    this.firmwares$?.unsubscribe();
    this.firmwares$ = this.listadosService
      .subscribe<IListado<IFirmware>>('firmwares', query)
      .subscribe((data) => {
        this.firmwares = data.datos;
        console.log(`listado de firmwares`, data);
      });
    await this.listadosService.getLastValue('firmwares', query);
  }

  private async listarClientes(): Promise<void> {
    const filter: IFilter<ICliente> = {};
    const query: IQueryParam = {
      filter: JSON.stringify(filter),
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }
  //

  private getData(): ICreateFirmwarePorCliente | IUpdateFirmwarePorCliente {
    const data: ICreateFirmwarePorCliente | IUpdateFirmwarePorCliente =
      this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      const firm = this.firmwares?.find((f) => f._id === data.idFirmware);
      data.tipo = firm?.dispositivo;
      data.version = firm?.version;
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    await Promise.all([this.listarFirmwares(), this.listarClientes()]);
  }
}
